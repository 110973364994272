import { useEffect, useRef } from "react";
import { useCanvas, useTool } from "../Context";
import style from "../css/Canvas.module.css";

const Canvas = () => {
  const canvasRef = useRef();
  const contextRef = useRef();
  const circleRef = useRef();
  const toolRef = useRef();
  const isDrawing = useRef(false);
  const isMoving = useRef(false);
  const offset = useRef({ x: 0, y: 0 });
  const [canvas, setCanvas] = useCanvas();
  const [tool, _] = useTool();

  const startTool = (e) => {
    e.preventDefault();
    if (!contextRef.current) return;
    switch (toolRef.current) {
      case "pen":
        startPen(e);
        break;
      case "eraser":
        startEraser(e);
        break;
      case "move":
        startMove(e);
        break;
      default:
    }
  };
  const moveTool = (e) => {
    e.preventDefault();
    const { offsetX, offsetY } = getEventCoordinates(e);
    if (circleRef.current) {
      circleRef.current.style.left = `${offsetX}px`;
      circleRef.current.style.top = `${offsetY}px`;
    }
    if (!contextRef.current) return;
    switch (toolRef.current) {
      case "pen":
        movePen(e);
        break;
      case "eraser":
        moveEraser(e);
        break;
      case "move":
        moveMove(e);
        break;
      default:
    }
  };
  const stopTool = (e) => {
    e.preventDefault();
    if (!contextRef.current) return;
    switch (toolRef.current) {
      case "pen":
        stopPen(e);
        break;
      case "eraser":
        stopEraser(e);
        break;
      case "move":
        stopMove(e);
        break;
      default:
    }
  };
  const startPen = (e) => {
    isDrawing.current = true;
    const { offsetX, offsetY } = getEventCoordinates(e);
    contextRef.current.beginPath();
    contextRef.current.moveTo(offsetX, offsetY);
  };
  const movePen = (e) => {
    const { offsetX, offsetY } = getEventCoordinates(e);
    if (!isDrawing.current) return;
    contextRef.current.lineTo(offsetX, offsetY);
    contextRef.current.stroke();
  };
  const stopPen = (e) => {
    isDrawing.current = false;

    setCanvas((state) => {
      const currentState = contextRef.current.getImageData(
        0,
        0,
        canvasRef.current.width,
        canvasRef.current.height
      );
      let history = [...state.history];
      const nextIndex = state.index;
      if (state.history[nextIndex]) history = history.slice(0, nextIndex);
      history = [...history, currentState];
      return { ...state, history: history, index: state.index + 1 };
    });
  };
  const startEraser = (e) => {
    isDrawing.current = true;
    const { offsetX, offsetY } = getEventCoordinates(e);
    contextRef.current.beginPath();
    contextRef.current.moveTo(offsetX, offsetY);
  };
  const moveEraser = (e) => {
    if (!isDrawing.current) return;
    const { offsetX, offsetY } = getEventCoordinates(e);

    contextRef.current.lineTo(offsetX, offsetY);
    contextRef.current.stroke();
  };
  const stopEraser = (e) => {
    isDrawing.current = false;
  };

  const startMove = (e) => {
    document.body.style.cursor = "grabbing";
    isMoving.current = true;
    const { offsetX, offsetY } = getEventCoordinates(e);
    offset.current = { x: offsetX, y: offsetY };
  };
  const moveMove = (e) => {
    if (!isMoving.current) return;
    const { offsetX, offsetY } = getEventCoordinates(e);
    const newOffsetX = offsetX - offset.current.x;
    const newOffsetY = offsetY - offset.current.y;
    offset.current = { x: offsetX, y: offsetY };
    const savedImageData = contextRef.current.getImageData(
      0,
      0,
      canvasRef.current.width,
      canvasRef.current.height
    );
    contextRef.current.clearRect(
      0,
      0,
      canvasRef.current.width,
      canvasRef.current.height
    );
    contextRef.current.putImageData(savedImageData, newOffsetX, newOffsetY);
  };
  const stopMove = (e) => {
    document.body.style.cursor = "default";
    isMoving.current = false;
  };

  const getEventCoordinates = (event) => {
    const rect = canvasRef.current.getBoundingClientRect();
    let offsetX, offsetY;
    if (event.type === "mousedown" || event.type === "mousemove") {
      offsetX = event.nativeEvent ? event.nativeEvent.offsetX : event.offsetX;
      offsetY = event.nativeEvent ? event.nativeEvent.offsetY : event.offsetY;
    } else if (event.type === "touchstart" || event.type === "touchmove") {
      const touch = event.changedTouches[0];
      offsetX = touch.pageX - rect.left;
      offsetY = touch.pageY - rect.top;
    }

    return { offsetX, offsetY };
  };

  useEffect(() => {
    if (!canvasRef.current) return;
    if (!contextRef.current) {
      const ctx = canvasRef.current.getContext("2d", {
        willReadFrequently: true,
      });
      contextRef.current = ctx;
      return;
    }
    contextRef.current.strokeStyle = canvas.color;
    contextRef.current.lineWidth = canvas.radius;
    contextRef.current.lineCap = "round";
    contextRef.current.lineJoin = "round";
  }, [canvas]);

  useEffect(() => {
    const canvas = canvasRef.current;
    canvas.addEventListener("mousedown", startTool, { passive: false });
    canvas.addEventListener("touchstart", startTool, { passive: false });
    canvas.addEventListener("mousemove", moveTool, { passive: false });
    canvas.addEventListener("touchmove", moveTool, { passive: false });
    canvas.addEventListener("mouseup", stopTool, { passive: false });
    canvas.addEventListener("touchend", stopTool, { passive: false });
    return () => {
      canvas.removeEventListener("mousedown", startTool, { passive: false });
      canvas.removeEventListener("touchstart", startTool, { passive: false });
      canvas.removeEventListener("mousemove", moveTool, { passive: false });
      canvas.removeEventListener("touchmove", moveTool, { passive: false });
      canvas.removeEventListener("mouseup", stopTool, { passive: false });
      canvas.removeEventListener("touchend", stopTool, { passive: false });
    };
  }, []);

  useEffect(() => {
    toolRef.current = tool;
    if (tool === "pen") {
      contextRef.current.globalCompositeOperation = "source-over";
      contextRef.current.strokeStyle = canvas.color;
      contextRef.current.lineWidth = canvas.radius;
    } else if (tool === "eraser") {
      contextRef.current.lineWidth = canvas.radius;
      contextRef.current.globalCompositeOperation = "destination-out";
      contextRef.current.strokeStyle = "rgba(0, 0, 0, 1)";
    }
  }, [tool]);
  if (!canvas) return null;
  return (
    <>
      <div
        className={style.canvas}
        style={{
          width: canvas.width,
          height: canvas.height,
        }}
      >
        <canvas
          id="canvas"
          ref={canvasRef}
          style={{ width: canvas.width, height: canvas.height }}
          width={canvas.width}
          height={canvas.height}
        ></canvas>
        {(tool === "pen" || tool === "eraser") && (
          <div
            ref={circleRef}
            className={style.circle}
            style={{
              width: canvas.radius,
              height: canvas.radius,
              left: 0,
              top: 0,
              transform: "translate(-50%, -50%)",
              pointerEvents: "none",
            }}
          ></div>
        )}
      </div>
    </>
  );
};
export default Canvas;
