import { useCanvas } from "./Context";
import Navigation from "./component/Navigation";
import Popup from "./component/Popup";
import Canvas from "./component/Canvas";
import style from "./css/App.module.css";
import Tool from "./component/Tool";
import Inspector from "./component/Inspector";
function App() {
  const [canvas, _] = useCanvas();
  return (
    <div className={style.container}>
      <Navigation />
      <div
        style={{
          display: "flex",
          flex: 1,
        }}
      >
        <Tool />
        <div
          style={{
            position: "relative",
            flex: 10,
            border: "2px solid #000",
            overflow: "auto",
          }}
        >
          {canvas && <Canvas />}
        </div>
        <Inspector />
      </div>
      <Popup />
    </div>
  );
}

export default App;
