import { usePopup } from "../Context";
import style from "../css/Navigation.module.css";

const Navigation = () => {
  const [_, setPopup] = usePopup();
  const reset = () => {
    const canvas = document.getElementById("canvas");
    if (!canvas) return;
    const ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, canvas.width, canvas.height);
  };
  return (
    <div className={style.container}>
      <div className={style.left}>
        <img draggable={false} src="images/drawcrafts.png" width={32} />
        <img
          draggable={false}
          src="images/new.png"
          width={32}
          onClick={() => setPopup("new")}
        />
        <img
          draggable={false}
          src="images/load.png"
          width={32}
          onClick={() => setPopup("load")}
        />
      </div>
      <div className={style.right}>
        <img
          draggable={false}
          src="images/help.png"
          width={32}
          onClick={() =>
            window.open(
              "https://carbonated-oyster-df1.notion.site/Draw-Crafts-250f3334689c4116959cbd674e237683?pvs=4",
              "_blank"
            )
          }
        />
        <img
          draggable={false}
          src="images/download.png"
          width={32}
          onClick={() => setPopup("download")}
        />
        <img
          draggable={false}
          src="images/reset.png"
          width={32}
          onClick={reset}
        />
      </div>
    </div>
  );
};
export default Navigation;
