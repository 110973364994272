import { useState } from "react";
import { usePopup, useCanvas } from "../Context";
import style from "../css/Popup.module.css";
const Popup = () => {
  const initialSize = {
    width: 0,
    height: 0,
  };
  const [popup, setPopup] = usePopup();
  const [canvas, setCanvas] = useCanvas();
  const [size, setSize] = useState(initialSize);

  const createCanvas = (e) => {
    e.preventDefault();
    if (size.width === 0 || size.height === 0) return;
    setCanvas((state) => ({
      ...state,
      width: Number(size.width),
      height: Number(size.height),
    }));
    setSize(initialSize);
    setPopup(null);
  };

  const load = (ev) => {
    ev.preventDefault();
    const file = ev.target.image.files[0];
    if (!file || !canvas.width || !canvas.height) return;
    console.log("file", file);
    const reader = new FileReader();
    reader.onload = (e) => {
      let image = new Image();
      image.onload = () => {
        const canvas = document.getElementById("canvas");
        const ctx = canvas.getContext("2d");
        ctx.drawImage(image, 0, 0);
        setPopup(null);
      };
      image.src = e.target.result;
    };
    // 파일을 Data URL로 읽습니다.
    reader.readAsDataURL(file);
  };
  const download = (size) => {
    const canvas = document.getElementById("canvas");
    const newWidth = canvas.width * size;
    const newHeight = canvas.height * size;
    const resizedCanvas = document.createElement("canvas");
    resizedCanvas.width = newWidth;
    resizedCanvas.height = newHeight;
    const resizedCtx = resizedCanvas.getContext("2d");
    resizedCtx.drawImage(canvas, 0, 0, newWidth, newHeight);
    const dataURL = resizedCanvas.toDataURL("image/png");

    const a = document.createElement("a");
    a.href = dataURL;
    a.download = "drawing.png";
    a.click();
  };
  if (!popup) return null;
  return (
    <div className={style.container}>
      {popup === "new" && (
        <form onSubmit={createCanvas} className={style.form}>
          <div className={style.title}>New Canvas</div>
          <div className={style.option}>
            <input
              type="range"
              value={size.width}
              min={0}
              max={1024}
              onChange={(e) => {
                setSize((state) => ({
                  ...state,
                  width:
                    Number(e.target.value) > 1024
                      ? 1024
                      : e.target.value.replace(/(^0+)/, ""),
                }));
              }}
            ></input>
            <input
              className={style.input}
              value={size.width}
              type="number"
              min={0}
              max={1024}
              onChange={(e) => {
                setSize((state) => ({
                  ...state,
                  width:
                    Number(e.target.value) > 1024
                      ? 1024
                      : e.target.value.replace(/(^0+)/, ""),
                }));
              }}
            ></input>
          </div>
          <div className={style.option}>
            <input
              type="range"
              value={size.height}
              min={0}
              max={1024}
              onChange={(e) =>
                setSize((state) => ({
                  ...state,
                  height:
                    Number(e.target.value) > 1024
                      ? 1024
                      : e.target.value.replace(/(^0+)/, ""),
                }))
              }
            ></input>
            <input
              className={style.input}
              value={size.height}
              min={0}
              max={1024}
              type="number"
              onChange={(e) =>
                setSize((state) => ({
                  ...state,
                  height:
                    Number(e.target.value) > 1024
                      ? 1024
                      : e.target.value.replace(/(^0+)/, ""),
                }))
              }
            ></input>
          </div>
          <button type="submit" className={style.create}>
            Create
          </button>
        </form>
      )}
      {popup === "load" && (
        <div className={style.form}>
          <form onSubmit={load}>
            <input
              id="image"
              type="file"
              accept="image/png, image/jpeg"
            ></input>
            <button type="submit">불러오기</button>
          </form>
        </div>
      )}
      {popup === "download" && (
        <div className={style.download}>
          <div className={style.options}>
            <button className={style.option} onClick={() => download(1)}>
              x1
            </button>
            <button className={style.option} onClick={() => download(2)}>
              x2
            </button>
            <button className={style.option} onClick={() => download(3)}>
              x3
            </button>
          </div>
          <div className={style.close} onClick={() => setPopup(null)}>
            Close
          </div>
        </div>
      )}
    </div>
  );
};
export default Popup;
