import { useTool, useCanvas } from "../Context";
import style from "../css/Inspector.module.css";
const Inspector = () => {
  const [tool, _] = useTool();
  const [canvas, setCanvas] = useCanvas();
  return (
    <div className={style.container}>
      {(tool === "pen" || tool === "eraser") && (
        <div className={style.component}>
          <label htmlFor="radius" className={style.label}>
            <img draggable={false} width={32} src="images/radius.png" alt="radius" />
            <span>{canvas.radius}</span>
          </label>
          <input
            className={style.range}
            onChange={(e) =>
              setCanvas((state) => ({
                ...state,
                radius: Number(e.target.value),
              }))
            }
            type="range"
            min={1}
            max={200}
            step={1}
            id="radius"
            value={canvas.radius}
          ></input>
        </div>
      )}
      {tool === "pen" && (
        <>
          <div className={style.component}>
            <label htmlFor="color" className={style.label}>
              <img draggable={false} width={32} src="images/color.png" alt="color" />
              <span>{canvas.color}</span>
            </label>
            <input
              className={style.range}
              type="color"
              id="color"
              onChange={(e) =>
                setCanvas((state) => ({ ...state, color: e.target.value }))
              }
            ></input>
          </div>
          <div className={style.component}>
            <label htmlFor="opacity" className={style.label}>
              <img draggable={false} width={32} src="images/opacity.png" alt="opacity" />
              <span>{canvas.opacity}</span>
            </label>
            <input
              className={style.range}
              onChange={(e) =>
                setCanvas((state) => ({
                  ...state,
                  opacity: Number(e.target.value),
                }))
              }
              type="range"
              min={0}
              max={1}
              step={0.01}
              id="opacity"
              value={canvas.opacity}
            ></input>
          </div>

          {/* <div>별</div> */}
        </>
      )}
    </div>
  );
};
export default Inspector;
