import { createContext, useState, useContext } from "react";
const GlobalContext = createContext();
const Context = ({ children }) => {
  const popup = useState();
  const tool = useState();
  const canvas = useState({
    width: 0,
    height: 0,
    color: null,
    radius: 1,
    opacity: 1,
    history: [],
    index: 0,
  });
  return (
    <GlobalContext.Provider value={{ popup, tool, canvas }}>
      {children}
    </GlobalContext.Provider>
  );
};
export const usePopup = () => {
  const globalState = useContext(GlobalContext);
  return globalState.popup;
};
export const useTool = () => {
  const globalState = useContext(GlobalContext);
  return globalState.tool;
};
export const useCanvas = () => {
  const globalState = useContext(GlobalContext);
  return globalState.canvas;
};

export default Context;
