import { useTool, useCanvas } from "../Context";
import style from "../css/Tool.module.css";

const Tool = () => {
  const [canvas, setCanvas] = useCanvas();
  const [tool, setTool] = useTool();
  const tools = ["pen", "eraser", "move"];
  const controls = ["back", "front"];
  const actions = {
    back: () => {
      const canvasEl = document.getElementById("canvas");
      const ctx = canvasEl.getContext("2d");
      const targetImage = canvas.history[canvas.index - 2];
      if (!targetImage) return;
      ctx.clearRect(0, 0, canvasEl.width, canvasEl.height);
      ctx.putImageData(targetImage, 0, 0);
      setCanvas((state) => ({ ...state, index: state.index - 1 }));
    },
    front: () => {
      const canvasEl = document.getElementById("canvas");
      const ctx = canvasEl.getContext("2d");
      const targetImage = canvas.history[canvas.index];
      ctx.clearRect(0, 0, canvasEl.width, canvasEl.height);
      ctx.putImageData(targetImage, 0, 0);
      setCanvas((state) => ({ ...state, index: state.index + 1 }));
    },
  };
  const actionClass = {
    back:
      canvas.history.length > 0 && canvas.index > 0
        ? style.active
        : style.inactive,
    front:
      canvas.history.length > 0 && canvas.index < canvas.history.length
        ? style.active
        : style.inactive,
  };
  return (
    <div className={style.container}>
      <div className={style.tools}>
        {tools.map((t, i) => (
          <div
            key={`tool_${t}`}
            onClick={() => setTool(t)}
            className={tool === t ? style.on : style.off}
          >
            <div className={style.icon}>
              <img
                draggable={false}
                src={`images/${t}.png`}
                alt={t}
                width="100%"
              />
            </div>
          </div>
        ))}
      </div>
      <div className={style.controls}>
        {controls.map((control, index) => (
          <div
            key={`${control}`}
            onClick={actions[control]}
            className={actionClass[control] && actionClass[control]}
          >
            <img draggable={false} width={36} src={`images/${control}.png`} />
          </div>
        ))}
      </div>
    </div>
  );
};
export default Tool;
